<template>
  <modal-card
    :title="`File snapshots for ${site.fqdn}`"
    :processing="processing"
  >
    <b-table
      :data="orderedSnapshots"
      :paginated="isPaginated"
      :per-page="perPage"
      :current-page.sync="currentPage"
      :pagination-simple="isPaginationSimple"
      :default-sort="['snapshot', 'desc']"
      :loading="processing"
    >
      <template slot-scope="props">
        <b-table-column field="snapshot" label="Backup date" sortable>
          <span>
            {{
              $moment(new Date(props.row.snapshot * 1000)).format(
                "Do MMM, YYYY @ HH:mm"
              )
            }}
          </span>
          <status-label
            v-if="props.row.pleasedeleteme"
            status="backup-deleted"
            class="has-margin-left-50"
          />
        </b-table-column>

        <b-table-column field="size" label="Size" width="120" sortable
          >~{{ humanFileSize(props.row.size) }}</b-table-column
        >

        <b-table-column label width="150" class="has-text-right">
          <span v-if="!props.row.pleasedeleteme">
            <a
              v-if="!props.row.deleting"
              class="has-text-dark"
              @click="restoreBackup(props.row)"
            >
              <u>Restore</u>
            </a>
            <span class="has-text-grey-light">|</span>
            <a class="has-text-danger" @click="deleteBackup(props.row)">
              <u>Delete</u>
            </a>
          </span>
          <a
            v-else
            class="has-text-dark"
            @click="undeleteFileSnapshot(props.row)"
          >
            <u>Undelete</u>
          </a>
        </b-table-column>
      </template>
    </b-table>
  </modal-card>
</template>

<script>
import { humanFileSize } from "@src/services/fileutil";
import { doc } from "@firebase/firestore";

export default {
  name: "FileSnapshotsModal",
  props: {
    siteId: {
      type: String,
      required: true
    },
    snapshots: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      processing: false,
      isPaginated: true,
      isPaginationSimple: true,
      currentPage: 1,
      perPage: 7
    };
  },
  computed: {
    site() {
      return this.$store.getters["sites/site"](this.siteId);
    },
    orderedSnapshots() {
      return this.$_.orderBy(this.snapshots, ["snapshot"], ["desc"]);
    }
  },
  methods: {
    restoreBackup(snapshot) {
      this.$store.dispatch("tasks/openAddTaskModal", {
        siteId: this.siteId,
        taskRef: doc(this.$firestore, "taskTypes", "restore-website"),
        default: `Please restore my site (${
          this.site.fqdn
        }) from the backup taken on ${this.$moment(
          new Date(snapshot.snapshot * 1000)
        ).format("Do MMM, YYYY @ h:mm a")}...`
      });
    },
    deleteBackup(snapshot) {
      const confirm = this.$confirm.open({
        parent: this,
        props: {
          autoClose: false,
          action: "Delete",
          message: `Are you sure you want to delete this backup ?`
        },
        canCancel: ["outside"],
        events: {
          confirmed: async () => {
            await this.deleteBackupConfirmed(snapshot);
            confirm.close();
          }
        }
      });
    },
    deleteBackupConfirmed(snapshot) {
      this.processing = true;
      return this.$store
        .dispatch("sites/backups/api", {
          type: "delete-file-snapshot",
          siteId: this.siteId,
          payload: {
            snapshot_id: snapshot.snapshot
          }
        })
        .then(() => {
          this.$set(snapshot, "pleasedeleteme", 1);
          this.$toast.open({
            message: "Snapshot marked for deletion",
            position: "is-bottom",
            queue: false
          });
        })
        .catch(() => {
          this.$toast.open({
            message: "Error deleting snapshot",
            position: "is-bottom",
            type: "is-danger",
            queue: false
          });
        })
        .finally(() => (this.processing = false));
    },
    undeleteFileSnapshot(snapshot) {
      this.processing = true;
      this.$store
        .dispatch("sites/backups/api", {
          type: "update-file-snapshot",
          siteId: this.siteId,
          payload: {
            snapshotId: snapshot.snapshot,
            fqdn: this.site.fqdn,
            pleasedeleteme: 0
          }
        })
        .then(() => {
          this.$set(snapshot, "pleasedeleteme", 0);
          this.$toast.open({
            message: "Snapshot undeleted",
            position: "is-bottom",
            queue: false
          });
        })
        .catch(e => {
          this.$toast.open({
            message: e.message, //"Error deleting file snapshot",
            position: "is-bottom",
            type: "is-danger",
            queue: false
          });
        })
        .finally(() => (this.processing = false));
    },
    humanFileSize(size) {
      // Because we get the size in KB we need to multiply by 1024
      return humanFileSize(size * 1024);
    }
  }
};
</script>
